main {
    min-height: 100vh;
    max-width: 580px;
    /* margin-top: 90px; */
}
/* main, body {
    box-sizing: border-box;
} */
/* html, body {height:100%; margin:0; padding:0;} */
@media (max-width: 500px) {
    main {
        max-width: 100vw;
    }
}

@media (max-width: 580px) {
    .hidden-xs {
        display: none !important;
    }
    main {
        max-width: 100vw;
        /* margin-top: 80px; */
    }
}

@media (min-width: 768px) {
    /* .hidden-desktop {
        display: none !important;
    }
    .header-middle__search .MuiFormControl-marginNormal {
        margin: 0;
    }
    .main-app {
        min-height: calc(100vh - 435px);
        margin-top: 140px;
        max-width: 580px;
    }
    .main-app-cms {
        margin-top: 0px;
    } */
}

@media (min-width: 1920) {
    .hidden-xl {
        display: none !important;
    }
}

@media (max-width: 767px) {
    .hidden-mobile {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width: 1024px) and (max-width: 1199px) {
    .hidden-md {
        display: none !important;
    }
}

@media (min-width: 1200px) and (max-width: 1919px) {
    .hidden-lg {
        display: none !important;
    }
}
